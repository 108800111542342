<template>
  <div
    class="modal fade top-z"
    id="feedbackModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalTitle"
    aria-hidden="true"
    data-backdrop="false"
  >
    <div class="modal-dialog modal modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header bg-info text-white">
          <h3>Feedback Request</h3>
        </div>
        <div class="modal-body d-flex justify-content-center" v-if="sending || sent">
          <p class="text-center" v-if="sending">
            <i
              class="fad fa-spin fa-spinner fa-3x mb-3 mx-auto text-primary"
            ></i>
          </p>
          <p class="text-center" v-else>
            <b class="mb-3 d-block">Thanks for your feedback!</b>
            <button class="d-block mt-3 btn btn-info p-1 w-100" @click="closeModal">Click here to exit</button>
          </p>
        </div>
        <div class="modal-body" v-else>
          <h4 class="p-2">Help us improve our product.</h4>
          <div class="form-group mt-2 p-2">
            <h4 class="mb-1">Your Overall Rating:</h4>
            <div class="form-check form-control mb-1">
              <input
                v-model="feedback"
                name="feedback"
                type="radio"
                :value="5"
              />
              <label class="ml-3"
                ><i class="fa fa-star" aria-hidden="true"></i
                ><i class="fa fa-star" aria-hidden="true"></i
                ><i class="fa fa-star" aria-hidden="true"></i
                ><i class="fa fa-star" aria-hidden="true"></i
                ><i class="fa fa-star" aria-hidden="true"></i
              ></label>
            </div>
            <div class="form-check form-control mb-1">
              <input
                v-model="feedback"
                name="feedback"
                type="radio"
                :value="4"
              />
              <label class="ml-3"
                ><i class="fa fa-star" aria-hidden="true"></i
                ><i class="fa fa-star" aria-hidden="true"></i
                ><i class="fa fa-star" aria-hidden="true"></i
                ><i class="fa fa-star" aria-hidden="true"></i
              ></label>
            </div>
            <div class="form-check form-control mb-1">
              <input
                v-model="feedback"
                name="feedback"
                type="radio"
                :value="3"
              />
              <label class="ml-3"
                ><i class="fa fa-star" aria-hidden="true"></i
                ><i class="fa fa-star" aria-hidden="true"></i
                ><i class="fa fa-star" aria-hidden="true"></i
              ></label>
            </div>
            <div class="form-check form-control mb-1">
              <input
                v-model="feedback"
                name="feedback"
                type="radio"
                :value="2"
              />
              <label class="ml-3"
                ><i class="fa fa-star" aria-hidden="true"></i
                ><i class="fa fa-star" aria-hidden="true"></i
              ></label>
            </div>
            <div class="form-check form-control mb-1">
              <input
                v-model="feedback"
                name="feedback"
                type="radio"
                :value="1"
              />
              <label class="ml-3"
                ><i class="fa fa-star" aria-hidden="true"></i
              ></label>
            </div>
            <div class="mb-1 mt-2">
              <label class="mb-1 display-block">Additional Comments:</label>
              <textarea
                v-model="feedback_comment"
                type="textbox"
                height="200px"
                class="form-control"
              />
            </div>
          </div>
          <div class="text-right p-2" v-if="!sending && !sent">
            <button @click="closeModal" class="btn btn-light mr-1">Skip</button>
            <button
              @click="sendFeedback"
              v-if="feedback > 0"
              class="btn btn-info mr-1"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["videoFeedbackID"],
  data: function () {
    return {
      feedback: 0,
      feedback_comment: "",
      sending: false,
      sent: false,
    };
  },
  methods: {
    closeModal() {
      $("#feedbackModal").modal("hide");
      this.$router.push('/appointments');
    },
    openModal() {
      $("#feedbackModal").modal("show");
    },
    sendFeedback() {
      this.sending = true;
      this.createFeedback();
    },
    createFeedback() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/client/video-feedback", {
          consultation_id: this.videoFeedbackID,
          feedback_rating: this.feedback,
          feedback_comment: this.feedback_comment,
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.sending = false;
          this.sent = true;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
};
</script>

<style scoped>
.top-z {
  z-index: 9999;
}
</style>