<template>
  <div>
    <Preflight
      v-if="!compatabilityChecks.checkCompatabilityComplete"
      :success="onCompatabilityChecksSuccess"
    />
    <div
      class="relative bg-black h-screen flex-col w-screen overflow-hidden"
      v-show="compatabilityChecks.checkCompatabilityComplete"
    >
      <div
        class="
          pdf-share
          flex flex-wrap
          h-100
          max-h-100
          overflow-scroll
          w-50
          align-center
          justify-center
          mx-auto
        "
        ref="pdf-render-container"
        v-if="fileBeingShared"
      >
        <pdf-viewer
          v-if="fileBeingShared"
          :current-page="currentPage"
          :page-count="pageCount"
          :file="fileBeingShared"
        ></pdf-viewer>
      </div>
      <div class="absolute top-2 mx-2 w-full">
        <div
          v-if="displayNotification.show"
          class="permissions-prompt mb-2 d-flex"
        >
          <p
            class="rounded p-2 text-gray font-bold inline"
            :class="displayNotification.type"
          >
            {{ displayNotification.message }}
          </p>
        </div>

        <!-- TODO: Build into new socket connection variables
        <div
          v-if="showPermissionsPrompt"
          class="permissions-prompt mb-2 flex w-full"
        >
          <p
            class="rounded bg-gray-200 p-2 text-gray md:text-2xl font-bold inline"
          >
            Please enable permissions for mic & camera
          </p>
        </div>

        <div
          v-if="this.remoteFeedCount == 0"
          class="permissions-prompt flex w-full"
        >
          <p
            class="rounded bg-gray-200 p-2 text-gray md:text-2xl font-bold inline"
          >
            Waiting for participant to join
          </p>
        </div>
        -->

        <video
          ref="miniScreen"
          class="hidden bg-yellow-200 z-30 mt-4 border"
          autoplay
          playsinline
          muted
          width="500px"
          height="300px"
        ></video>
      </div>

      <div
        class="
          full-screen-video
          flex flex-wrap
          justify-center
          items-center
          h-screen
          bg-black
        "
      >
        <video
          ref="remoteScreenVideoElement"
          class="remote-screen-video hidden h-full w-auto"
          autoplay
          playsinline
        ></video>

        <div
          ref="remoteVideosFull"
          id="remote-videos-full"
          class="remote-videos-full flex justify-center h-full grid gap-4"
          :class="'grid-cols-' + remoteGridColumns"
        ></div>
      </div>
      <div
        class="
          video-gallery
          absolute
          w-full
          bottom-0
          h-1/6
          flex flex-wrap
          items-center
          bg-gray-400
          border-top-2 border-gray-200
          bg-opacity-75
        "
      >
        <div class="control-buttons ml-2 mr-auto items-end">
          <control-area
            :connected="connected"
            :audio-muted="audioMuted"
            :video-muted="videoEnabled"
            :local-screen-share="localScreenShare"
            :sharing-screen="sharingScreen"
            :screen-button-busy="screenButtonBusy"
            @toggleAudioMute="toggleAudioMute"
            @toggleVideoMute="toggleVideoMute"
            @startScreenShare="startScreenShare"
            @endScreenShare="endScreenShare"
            @endConnection="endConnection"
          ></control-area>
        </div>
        <div
          ref="remoteVideosGallery"
          id="remote-videos-container"
          class="remote-videos h-28 flex flex-wrap ml-auto"
        ></div>
        <div id="local-video-container" class="local-video rounded h-28 mr-2">
          <video
            ref="localVideoElement"
            class="local-video rounded h-full w-24 md:w-auto"
            :class="hasVideo ? '' : 'hidden'"
            autoplay
            playsinline
            muted
          ></video>
          <div
            ref="noLocalCamera"
            id="no-local-camera"
            class="
              w-24
              md:w-36
              h-full
              bg-gray-200
              justify-center
              items-center
            "
            :class="hasVideo ? 'hidden' : 'flex'"
          >
            <p>No camera</p>
          </div>
        </div>
      </div>
      <feedback-modal ref="FBModal" :videoFeedbackID="roomId" />
    </div>
  </div>
</template>

<script>
import "../../../assets/styles/tailwind.css";

import * as io from "socket.io-client";
import { MicTest } from "./MicTest.js";
import PdfViewer from "./partials/PdfViewer";

import { Janus } from "janus-gateway";
//
import ControlArea from "./partials/ControlArea.vue";

import FeedbackModal from "./partials/Feedback";

import Preflight from "./Preflight.vue";

export default {
  name: "JanusVideoRoom",
  props: {
    roomData: {
      type: Object,
      default: () => ({
        id: 2150,
      }),
      validator(x) {
        return x != null;
      },
    },
    userData: {
      type: Object,
      default: () => ({
        name: "Test Testingtons",
        email: "test@xaylo.com",
      }),
      validator(x) {
        return x != null;
      },
    },
    streamEnabled: {
      type: Boolean,
      default: false,
    },
    serverUrl: {
      type: Array,
      default: [
        "wss://janus.swandoola.com/ws",
        "https://janus.swandoola.com/janus",
      ],
    },
    desktopCapturer: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      role: "publisher",
      subscriberMode: false,
      janusConnection: null,
      sfutest: null,
      showPermissionsPrompt: true,
      connected: false,
      myid: null,
      mypvtid: null,
      mystream: null,
      feeds: [],
      remoteFeedCount: 0,

      //
      audioMuted: false,
      videoEnabled: false,
      sharingScreen: false,
      myScreenId: null,
      myPrivateScreenId: null,

      //

      screenVideoElement: null,
      screenShare: null,
      screenSources: [],
      localScreenShare: false,
      remoteScreenShare: false,
      selectedScreenSource: null,
      screenButtonBusy: false,
      localScreenStream: null,
      //
      screenCaptureSettings: {
        cursor: "never", // always, motion, never
      },
      //
      currentPage: 1,
      pageCount: 0,
      //
      isConnected: false,
      socketMessage: "",
      socket: null,
      //
      fileBeingShared: null,

      displayNotification: {
        show: false,
        message: "",
        type: "bg-light",
      },
      tempDisconnect: 0,

      dataReport: {
        user: this.$store.user.id,
        room: this.$route.params.id,
        userAgent: navigator.userAgent,
        events: [],
      },

      compatabilityChecks: {
        checkCompatabilityComplete: false,
        apiValidated: false,
        devicesFound: false,
        streamRetrievingProblem: false,
      },

      p2pSocket: null,
      socketInitialized: false,
      peerConnections: {},
      pcOptions: {
        offerToReceiveAudio: 1,
        offerToReceiveVideo: 1
      },
      candidates: [],
      users: [],
      userContact: -1,
      localStream: null,
      remoteStream: null,
      screenShareStream: null,
      peerConnectionTimers: {},
      packetsLostThreshold: 25,
      notificationType: "",
      notificationTimeout: null,
    };
  },
  computed: {
    hasVideo() {
      return this.localStream && !this.videoEnabled;
    },
    opaqueId() {
      return this.userData.email;
    },
    screenShareId() {
      return "***SCREEN***" + this.userData.email;
    },
    roomId() {
      return parseInt(this.roomData.id);
    },
    remoteGridColumns() {
      if (this.remoteFeedCount <= 3) {
        return this.remoteFeedCount;
      } else {
        return 3;
      }
    },
    miniScreen() {
      return this.$refs.miniScreen;
    },
  },
  methods: {
    // == PreFlight Check == //
    async checkCompatability() {
      try {
        let config = { audio: true, video: true };
        let _vm = this;
        let stream = await navigator.mediaDevices.getUserMedia(config);
        console.log(stream);
        this.compatabilityChecks.apiValidated = true;
        this.compatabilityChecks.checkCompatabilityComplete = true;
        this.showPermissionsPrompt = false;
        //navigator.mediaDevices.enumerateDevices().then(function (devices) {
        //  console.log(devices);
        //  _vm.devicesFound = true;
        //});
      } catch (error) {
        //alert(`${error}`);
        console.log(error);
        this.compatabilityChecks.apiValidated = false;
        this.compatabilityChecks.checkCompatabilityComplete = true;
        return false;
      }

      return true;
    },
    onCompatabilityChecksSuccess(mediaStream) {
      this.compatabilityChecks.checkCompatabilityComplete = true;

      mediaStream.getAudioTracks()[0].enabled = !this.audioMuted;
      mediaStream.getVideoTracks()[0].enabled = !this.videoEnabled;

      this.localStream = mediaStream;
      this.$refs.localVideoElement.srcObject = this.localStream;

      this.micTest = new MicTest(this.localStream, (result) => {
        if (result || this.audioMuted) {
          this.hideNotification("sound");
        } else {
          this.notificationType = "sound";
          this.showNotification("No sound detected, please check your microphone", "warning", 4000);
        }
      });
      this.micTest.start();

      this.connected = true;

      this.initializeSocket();
    },
    // === UI Handlers === //
    handleICEState(state) {
      switch (state) {
        case "failed":
        case "closed":
        case "disconnected":
          this.showNotification("Connection Status: Disconnected", "danger", 0);
          this.notificationType = "iceState";
          this.tempDisconnect = 1;
          break;
        case "connected":
          if (this.tempDisconnect) {
            this.showNotification(
              "Connection Status: Connected",
              "success",
              4000
            );
            this.notificationType = "iceState";
          }
          break;
        case "new":
        case "checking":
          if (this.tempDisconnect) {
            this.showNotification(
              "Connection Status: Checking...",
              "success",
              4000
            );
            this.notificationType = "iceState";
          }
          break;
      }
    },
    handleSlowLink(uplink, lost) {
      // uplink = false is a problem sending packets to server
      // lost is quantity of packets
      if (uplink == false) {
        this.showNotification(
          "Connection Status: Slow Connection",
          "warning"
        );
        this.notificationType = "packetsLost";
      }
    },
    // === UI Methods ==== //
    showNotification(message, type, duration = 0) {
      clearTimeout(this.notificationTimeout);

      this.displayNotification.message = message;
      this.displayNotification.type = "bg-" + type;
      this.displayNotification.show = true;
      if (duration !== 0) {
        this.notificationTimeout = setTimeout(() => (this.displayNotification.show = false), duration);
      }
    },
    hideNotification(type) {
      if (type && type == this.notificationType) {
        clearTimeout(this.notificationTimeout);
        this.notificationType = "";
        this.displayNotification.show = false;
      }
    },
    // =================== //
    toggleAudioMute() {
      this.audioMuted = !this.audioMuted;

      if (this.localStream) {
        this.localStream.getAudioTracks()[0].enabled = !this.audioMuted;
      }

      // this.audioMuted = this.sfutest.isAudioMuted();
      // Janus.log((this.audioMuted ? "Unmuting" : "Muting") + " local stream...");
      // if (this.audioMuted) {
      //   this.sfutest.unmuteAudio();
      // } else {
      //   this.sfutest.muteAudio();
      // }
      // this.audioMuted = this.sfutest.isAudioMuted();
    },
    toggleVideoMute() {
      this.videoEnabled = !this.videoEnabled;

      if (this.localStream) {
        this.localStream.getVideoTracks()[0].enabled = !this.videoEnabled;
      }

      // Janus.log(
      //   (this.videoEnabled ? "Enabling" : "Disabling") +
      //     " local video stream..."
      // );

      // if (this.videoEnabled) {
      //   this.$refs.localVideoElement.classList.remove("hidden");
      //   this.$refs.noLocalCamera.classList.add("hidden");
      // } else {
      //   this.$refs.localVideoElement.classList.add("hidden");
      //   this.$refs.noLocalCamera.classList.remove("hidden");
      // }
      // this.sfutest.send({
      //   message: {
      //     request: "configure",
      //     video: this.videoEnabled,
      //   },
      // });

      // this.videoEnabled = !this.videoEnabled;
    },
    endConnection() {
      this.clearPeerconnections();

      // this.janusConnection.destroy();
      // window.close();
      this.sendLog();
      this.$refs.FBModal.openModal();
    },

    //
    initializeJanus() {
      Janus.init({
        debug: true,
        dependencies: Janus.useDefaultDependencies(),
        callback: () => {
          console.log("Janus has been setup");
          this.connectToJanus();
        },
      });
    },

    connectToJanus() {
      this.janusConnection = new Janus({
        server: this.serverUrl,
        success: () => {
          // Done! attach to plugin XYZ
          this.attachVideoRoomPlugin();
        },
        error: (cause) => {
          // Error, can't go on...
        },
        destroyed: () => {
          // I should get rid of this
        },
      });
    },

    attachVideoRoomPlugin() {
      this.janusConnection.attach({
        plugin: "janus.plugin.videoroom",
        opaqueId: this.opaqueId,
        success: (pluginHandle) => {
          this.sfutest = pluginHandle;
          Janus.log(
            "Plugin attached! (" +
              this.sfutest.getPlugin() +
              ", id=" +
              this.sfutest.getId() +
              ")"
          );
          Janus.log("  -- This is a publisher/manager");

          this.addEvent("Event: Attached VideoRoom as publisher");

          this.checkJanusRoomExists();
        },
        error: (error) => {
          Janus.error("  -- Error attaching plugin...", error);
          console.log("Error attaching plugin... " + error);
        },
        consentDialog: (on) => {
          this.showPermissionsPrompt = on;
        },
        iceState: (state) => {
          Janus.log("ICE state changed to " + state);
          this.handleICEState(state);
        },
        mediaState: (medium, on) => {
          Janus.log(
            "Janus " + (on ? "started" : "stopped") + " receiving our " + medium
          );
        },
        slowLink: (uplink, lost) => {
          this.handleSlowLink(uplink, lost);
          Janus.log(
            "SlowLink Data: [uplink: " + uplink + "] [lost: " + lost + "]"
          );
        },
        webrtcState: (on) => {
          Janus.log(
            "Janus says our WebRTC PeerConnection is " +
              (on ? "up" : "down") +
              " now"
          );
        },
        onmessage: (msg, jsep) => {
          Janus.debug(" ::: Got a message (publisher) :::", msg);
          var event = msg["videoroom"];
          Janus.debug("Event: " + event);
          if (event) {
            if (event === "joined") {
              // Publisher/manager created, negotiate WebRTC and attach to existing this.feeds, if any
              this.myid = msg["id"];
              this.mypvtid = msg["private_id"];
              Janus.log(
                "Successfully joined room " +
                  msg["room"] +
                  " with ID " +
                  this.myid
              );
              if (this.subscriberMode) {
                // Should be able to see videos but not publish own feed
              } else {
                this.publishOwnFeed(true);
              }
              // Any new feed to attach to?
              if (msg["publishers"]) {
                var list = msg["publishers"];
                Janus.debug("Got a list of available publishers/feeds:", list);
                for (var f in list) {
                  var id = list[f]["id"];
                  var display = list[f]["display"];
                  var audio = list[f]["audio_codec"];
                  var video = list[f]["video_codec"];
                  Janus.debug(
                    "  >> [" +
                      id +
                      "] " +
                      display +
                      " (audio: " +
                      audio +
                      ", video: " +
                      video +
                      ")"
                  );
                  this.newRemoteFeed(id, display, audio, video);
                }
              }
            } else if (event === "destroyed") {
              // The room has been destroyed
              Janus.warn("The room has been destroyed!");
              console.log("The room has been destroyed", () => {
                window.location.reload();
              });
            } else if (event === "event") {
              // Any new feed to attach to?
              if (msg["publishers"]) {
                var list = msg["publishers"];
                Janus.debug("Got a list of available publishers/feeds:", list);
                for (var f in list) {
                  var id = list[f]["id"];
                  var display = list[f]["display"];
                  var audio = list[f]["audio_codec"];
                  var video = list[f]["video_codec"];
                  Janus.debug(
                    "  >> [" +
                      id +
                      "] " +
                      display +
                      " (audio: " +
                      audio +
                      ", video: " +
                      video +
                      ")"
                  );
                  this.newRemoteFeed(id, display, audio, video);
                }
              } else if (msg["leaving"]) {
                // One of the publishers has gone away?
                var leaving = msg["leaving"];
                Janus.log("Publisher left: " + leaving);
                var remoteFeed = null;
                for (var i = 1; i < 6; i++) {
                  if (this.feeds[i] && this.feeds[i].rfid == leaving) {
                    remoteFeed = this.feeds[i];
                    break;
                  }
                }
                if (remoteFeed != null) {
                  Janus.debug(
                    "Feed " +
                      remoteFeed.rfid +
                      " (" +
                      remoteFeed.rfdisplay +
                      ") has left the room, detaching"
                  );
                  $("#remote" + remoteFeed.rfindex)
                    .empty()
                    .hide();
                  $("#videoremote" + remoteFeed.rfindex).empty();
                  this.feeds[remoteFeed.rfindex] = null;
                  remoteFeed.detach();
                }
              } else if (msg["unpublished"]) {
                // One of the publishers has unpublished?
                var unpublished = msg["unpublished"];
                Janus.log("Publisher left: " + unpublished);
                if (unpublished === "ok") {
                  // That's us
                  this.sfutest.hangup();
                  return;
                }
                var remoteFeed = null;
                for (var i = 1; i < 6; i++) {
                  if (this.feeds[i] && this.feeds[i].rfid == unpublished) {
                    remoteFeed = this.feeds[i];
                    break;
                  }
                }
                if (remoteFeed != null) {
                  Janus.debug(
                    "Feed " +
                      remoteFeed.rfid +
                      " (" +
                      remoteFeed.rfdisplay +
                      ") has left the room, detaching"
                  );
                  $("#remote" + remoteFeed.rfindex)
                    .empty()
                    .hide();
                  $("#videoremote" + remoteFeed.rfindex).empty();
                  this.feeds[remoteFeed.rfindex] = null;
                  remoteFeed.detach();
                }
              } else if (msg["error"]) {
                if (msg["error_code"] === 426) {
                  // This is a "no such room" error: give a more meaningful description
                  console.log("the room doesnt exist", this.roomId);
                } else {
                  console.log(msg["error"]);
                }
              }
            }
          }
          if (jsep) {
            Janus.debug("Handling SDP as well...", jsep);
            this.sfutest.handleRemoteJsep({ jsep: jsep });
            // Check if any of the media we wanted to publish has
            // been rejected (e.g., wrong or unsupported codec)
            var audio = msg["audio_codec"];
            if (
              this.mystream &&
              this.mystream.getAudioTracks() &&
              this.mystream.getAudioTracks().length > 0 &&
              !audio
            ) {
              // Audio has been rejected
              console.log(
                "Our audio stream has been rejected, viewers won't hear us"
              );
            }
            var video = msg["video_codec"];
            if (
              this.mystream &&
              this.mystream.getVideoTracks() &&
              this.mystream.getVideoTracks().length > 0 &&
              !video
            ) {
              // Video has been rejected
              console.log(
                "Our video stream has been rejected, viewers won't see us"
              );
              // Hide the webcam video
              $("#myvideo").hide();
              $("#videolocal").append(
                '<div class="no-video-container">' +
                  '<i class="fa fa-video-camera fa-5 no-video-icon" style="height: 100%;"></i>' +
                  '<span class="no-video-text" style="font-size: 16px;">Video rejected, no webcam</span>' +
                  "</div>"
              );
            }
          }
        },
        onlocalstream: (stream) => {
          Janus.debug(" ::: Got a local stream :::", stream);
          this.mystream = stream;
          Janus.attachMediaStream(this.$refs.localVideoElement, stream);
          if (
            this.sfutest.webrtcStuff.pc.iceConnectionState !== "completed" &&
            this.sfutest.webrtcStuff.pc.iceConnectionState !== "connected"
          ) {
            // Show connecting spinner or something?
          }
          var noLocalCameraImage = document.getElementById("no-local-camera");

          var videoTracks = stream.getVideoTracks();
          if (!videoTracks || videoTracks.length === 0) {
            // No webcam
            //  Show no camera image
            noLocalCameraImage.classList.remove("hidden");
          } else {
            // Hide no camera image
            // display local video
            this.$refs.localVideoElement.classList.remove("hidden");
            noLocalCameraImage.classList.add("hidden");
          }

          this.connected = true;
        },
        onremotestream: (stream) => {
          // The publisher stream is sendonly, we don't expect anything here
        },
        oncleanup: () => {
          Janus.log(
            " ::: Got a cleanup notification: we are unpublished now :::"
          );
          this.mystream = null;
          // Can reconnect at this stage using
          // this.publishOwnFeed(true);
        },
      });
    },
    checkJanusRoomExists() {
      var exists = {
        request: "exists",
        room: this.roomId,
      };

      var create = {
        request: "create",
        room: this.roomId,
        publishers: 6,
        audiolevel_ext: true,
        audiolevel_event: true,
        audio_active_packets: 100,
        audio_level_average: 25,
      };

      this.sfutest.send({
        message: exists,
        success: (result) => {
          console.log("checking if room exists: ", result);
          if (result.exists) {
            this.addEvent("Room Exists Already - Joining...");
            this.registerLocalUser();
            return true;
          } else {
            this.addEvent(
              "Created Room with request: " + JSON.stringify(create)
            );
            this.sfutest.send({
              message: create,
              success: () => {
                this.checkJanusRoomExists();
              },
            });
          }
        },
      });
    },
    registerLocalUser() {
      var register = {
        request: "join",
        room: this.roomId,
        ptype: "publisher",
        display: this.userData.email,
      };
      this.addEvent(
        "Registered User with request: " + JSON.stringify(register)
      );
      this.sendJanusMessage(register);
    },
    sendJanusMessage(message) {
      this.sfutest.send({
        message: message,
      });
    },

    publishOwnFeed(useAudio) {
      // Publish our stream
      this.sfutest.createOffer({
        // Add data:true here if you want to publish datachannels as well
        media: {
          audioRecv: false,
          videoRecv: false,
          audioSend: useAudio,
          videoSend: true,
        }, // Publishers are sendonly
        simulcast: false,
        simulcast2: false,
        success: (jsep) => {
          Janus.debug("Got publisher SDP!", jsep);
          this.addEvent("Publishing Feed Successful");
          var publish = { request: "configure", audio: useAudio, video: true };
          this.sfutest.send({ message: publish, jsep: jsep });
        },
        error: (error) => {
          Janus.error("WebRTC error:", error);
          this.addEvent(
            "addReport",
            "[ERROR] [WEBRTC] " + JSON.stringify(error)
          );
          if (useAudio) {
            this.publishOwnFeed(false);
          } else {
            console.log("WebRTC error... " + error.message);
            this.publishOwnFeed(true);
          }
        },
      });
    },

    newRemoteFeed(id, display, audio, video) {
      // A new feed has been published, create a new plugin handle and attach to it as a subscriber
      var remoteFeed = null;
      this.addEvent("remote feed with video type: " + video);
      this.janusConnection.attach({
        plugin: "janus.plugin.videoroom",
        opaqueId: this.opaqueId,
        success: (pluginHandle) => {
          remoteFeed = pluginHandle;
          remoteFeed.simulcastStarted = false;
          Janus.log(
            "Plugin attached! (" +
              remoteFeed.getPlugin() +
              ", id=" +
              remoteFeed.getId() +
              ")"
          );
          Janus.log("  -- This is a subscriber");
          this.addEvent("Plugin attached (subscriber)");
          // We wait for the plugin to send us an offer
          var subscribe = {
            request: "join",
            room: this.roomId,
            ptype: "subscriber",
            feed: id,
            private_id: this.mypvtid,
          };
          // In case you don't want to receive audio, video or data, even if the
          // publisher is sending them, set the 'offer_audio', 'offer_video' or
          // 'offer_data' properties to false (they're true by default), e.g.:
          //    subscribe["offer_video"] = false;
          // For example, if the publisher is VP8 and this is Safari, let's avoid video
          if (
            Janus.webRTCAdapter.browserDetails.browser === "safari" &&
            (video === "vp9" || (video === "vp8" && !Janus.safariVp8))
          ) {
            if (video) video = video.toUpperCase();
            console.log(
              "Publisher is using " +
                video +
                ", but Safari doesn't support it: disabling video"
            );
            this.addEvent(
              "[ERROR] [CODEC] Publisher is using " +
                video +
                " but Safari doesn't support it"
            );
            subscribe["offer_video"] = false;
          }
          remoteFeed.videoCodec = video;
          remoteFeed.send({ message: subscribe });
        },
        error: (error) => {
          Janus.error("  -- Error attaching plugin...", error);
          console.log("Error attaching plugin... " + error);
          this.addEvent("[ERROR] Error attaching plugin...");
        },
        onmessage: (msg, jsep) => {
          console.log("not sure what happened", msg, jsep);
          Janus.debug(" ::: Got a message (subscriber) :::", msg);
          var event = msg["videoroom"];
          Janus.debug("Event: " + event);
          if (msg["error"]) {
            console.log(msg["error"]);
          } else if (event) {
            if (event === "attached") {
              // Subscriber created and attached
              for (var i = 1; i < 6; i++) {
                if (!this.feeds[i]) {
                  this.feeds[i] = remoteFeed;
                  remoteFeed.rfindex = i;
                  break;
                }
              }
              remoteFeed.rfid = msg["id"];
              remoteFeed.rfdisplay = msg["display"];

              Janus.log(
                "Successfully attached to feed " +
                  remoteFeed.rfid +
                  " (" +
                  remoteFeed.rfdisplay +
                  ") in room " +
                  msg["room"]
              );

              // Show the remote feed - what is the html in the rfdisplay - ah its display username!!
            } else if (event === "event") {
              // Check if we got a simulcast-related event from this publisher
            } else {
              // What has just happened?
              console.log("not sure what happened", msg, jsep);
            }
          }
          if (jsep) {
            Janus.debug("Handling SDP as well...", jsep);
            // Answer and attach
            remoteFeed.createAnswer({
              jsep: jsep,
              // Add data:true here if you want to subscribe to datachannels as well
              // (obviously only works if the publisher offered them in the first place)
              media: { audioSend: false, videoSend: false }, // We want recvonly audio/video
              success: (jsep) => {
                Janus.debug("Got SDP!", jsep);
                var body = { request: "start", room: this.roomId };
                remoteFeed.send({ message: body, jsep: jsep });
              },
              error: (error) => {
                Janus.error("WebRTC error:", error);
                console.log("WebRTC error... " + error.message);
                this.addEvent(
                  "[ERROR] [WEBRTC] (create answer)" + JSON.stringify(error)
                );
              },
            });
          }
        },
        iceState: (state) => {
          Janus.log(
            "ICE state of this WebRTC PeerConnection (feed #" +
              remoteFeed.rfindex +
              ") changed to " +
              state
          );
        },
        webrtcState: (on) => {
          Janus.log(
            "Janus says this WebRTC PeerConnection (feed #" +
              remoteFeed.rfindex +
              ") is " +
              (on ? "up" : "down") +
              " now"
          );
        },
        onlocalstream: (stream) => {
          // The subscriber stream is recvonly, we don't expect anything here
        },
        onremotestream: (stream) => {
          Janus.debug(
            "Remote feed #" + remoteFeed.rfindex + ", stream:",
            stream
          );

          //

          if (remoteFeed.rfdisplay.includes("***SCREEN***")) {
            // The incoing feed is a screen
            this.screenShare = true;

            if (!remoteFeed.rfdisplay.includes(this.userData.email)) {
              this.setRemoteStream(stream, true);
            }
          } else {
            if (!remoteFeed.rfdisplay.includes(this.userData.email)) {
              this.setRemoteStream(stream, false);
              // Janus.attachMediaStream(actualVideoEl, stream);
            }
          }

          var videoTracks = stream.getVideoTracks();
          if (!videoTracks || videoTracks.length === 0) {
            // No remote video
          } else {
          }
        },
        oncleanup: () => {
          Janus.log(
            " ::: Got a cleanup notification (remote feed " + id + ") :::"
          );
          if (remoteFeed.spinner) remoteFeed.spinner.stop();
          remoteFeed.spinner = null;
          var remoteVideoDiv = document.getElementById(
            "remote-video-div-" + remoteFeed.rfindex
          );
          if (remoteVideoDiv) {
            remoteVideoDiv.remove();
            this.remoteFeedCount--;
          }

          if (remoteFeed.rfdisplay.includes("***SCREEN***")) {
            this.$refs.remoteVideosFull.classList.remove(
              "screen-sharing-active"
            );

            document
              .getElementById("remote-videos-full")
              .classList.remove("screen-sharing-active");

            this.$refs.remoteVideosFull.classList.add("h-full");

            var allRemoteVideos = document.getElementsByClassName(
              "remote-video-player"
            );
            allRemoteVideos.forEach((v) => {
              v.classList.remove("h-28");
              v.classList.add("h-screen");
            });

            this.$refs.remoteScreenVideoElement.classList.add("hidden");
          }

          remoteFeed.simulcastStarted = false;
        },
      });
    },

    // Screen sharing

    async startScreenShare() {
      if (this.isElectron) {
        this.enableScreenShareElectron();
      } else {
        var displayMediaOptions = {
          video: {
            cursor: this.screenCaptureSettings.cursor,
          },
          audio: false,
        };

        var stream = await navigator.mediaDevices.getDisplayMedia(
          displayMediaOptions
        );
        this.selectScreenSource(stream);
      }
    },

    async selectScreenSource(stream) {
      this.localScreenShare = true;
      this.publishScreen(stream);
      this.screenSources = [];
    },

    publishScreen(stream) {
      this.listenForScreenShareEnd(stream);

      this.screenShareStream = stream;
      let peerConnection = this.peerConnections['screen'];

      if (peerConnection) {
        stream.getTracks().forEach(track => peerConnection.addTrack(track, stream));
        this.setVP8Codec(peerConnection, stream);
      } else {
        this.startPeerConnection(this.screenShareStream, true);
      }

      this.createOffer(true);

      this.localScreenStream = stream;
      this.miniScreen.srcObject = stream;
      this.miniScreen.classList.remove("hidden");

      this.screenVideoElement = document.createElement("video");
      this.screenVideoElement.srcObject = stream;
      this.screenVideoElement.setAttribute('autoplay', true);
      this.screenVideoElement.setAttribute('playsinline', true);

      // this.registerScreenUsername();

      // this.janusConnection.attach({
      //   plugin: "janus.plugin.videoroom",
      //   opaqueId: this.screenShareId,
      //   success: (pluginHandle) => {
      //     this.screenConnection = pluginHandle;
      //     var subscribe = {
      //       request: "join",
      //       room: this.roomId,
      //       ptype: "publisher",
      //       private_id: this.myPrivateScreenId,
      //       display: this.screenShareId,
      //       quality: 0,
      //     };

      //     this.screenConnection.send({
      //       message: subscribe,
      //     });
      //   },
      //   error: (error) => {
      //     Janus.error("  -- Error attaching plugin...", error);
      //     console.log("Error attaching plugin... " + error);
      //   },
      //   onmessage: (msg, jsep) => {
      //     Janus.debug(" ::: Got a message (publisher) :::", msg);
      //     var event = msg["videoroom"];
      //     Janus.debug("Event: " + event);
      //     if (event) {
      //       if (event === "joined") {
      //         this.myScreenId = msg["id"];
      //         this.myPrivateScreenId = msg["private_id"];
      //         Janus.log(
      //           "Successfully joined room " +
      //             msg["room"] +
      //             " with ID " +
      //             this.myScreenId
      //         );
      //         if (this.role === "publisher") {
      //           this.screenConnection.createOffer({
      //             stream: stream,
      //             media: {
      //               video: true,
      //               audioSend: true,
      //               videoRecv: false,
      //             }, // Screen sharing Publishers are sendonly
      //             success: (jsep) => {
      //               Janus.debug("Got publisher SDP!", jsep);
      //               var publish = {
      //                 request: "configure",
      //                 audio: true,
      //                 video: true,
      //               };
      //               this.screenConnection.send({
      //                 message: publish,
      //                 jsep: jsep,
      //               });
      //             },
      //             error: (error) => {
      //               Janus.error("WebRTC error:", error);
      //               console.log("WebRTC error... " + error.message);
      //             },
      //           });
      //         }
      //       }
      //     }
      //     if (jsep) {
      //       Janus.debug("Handling SDP as well...", jsep);
      //       this.screenConnection.handleRemoteJsep({
      //         jsep: jsep,
      //       });
      //     }
      //   },
      //   onlocalstream: (stream) => {
      //     Janus.debug(" ::: Got a local screen stream :::", stream);
      //     this.localScreenStream = stream;
      //     this.miniScreen.srcObject = stream;
      //     this.miniScreen.classList.remove("hidden");

      //     this.screenVideoElement = document.createElement("video");
      //     this.screenVideoElement.srcObject = stream;
      //     this.screenVideoElement.autoplay = true;

      //     if (
      //       this.sfutest.webrtcStuff.pc.iceConnectionState !== "completed" &&
      //       this.sfutest.webrtcStuff.pc.iceConnectionState !== "connected"
      //     ) {
      //       console.log("connecting local video");
      //       // show a spinner or something
      //     }
      //     var videoTracks = stream.getVideoTracks();
      //     if (!videoTracks || videoTracks.length === 0) {
      //       // No webcam
      //     }
      //   },
      //   webrtcState: (on) => {
      //     if (on) {
      //       this.screenConnection.send({
      //         message: {
      //           request: "configure",
      //           bitrate: 0,
      //         },
      //       });
      //     }
      //   },
      // });
    },

    listenForScreenShareEnd(stream) {
      stream.getVideoTracks()[0].addEventListener("ended", () => {
        console.log("Stop button pressed in browser");
        this.endScreenShare();
      });
    },

    endScreenShare() {
      this.screenButtonBusy = true;

      this.p2pSocket.emit('stream_ended', {
        isScreen: true,
      });

      if (this.screenShareStream) {
        this.screenShareStream.getTracks()
          .forEach((track) => {
            track.enable = false;
            track.stop && track.stop();
          });

        this.screenShareStream = null;
      }

      if (!this.screenShare && this.peerConnections['screen']) {
        this.peerConnections['screen'].close();
        this.peerConnections['screen'] = null;
      }

      this.localScreenShare = false;
      this.screenButtonBusy = false;

      this.miniScreen.srcObject = null;
      this.miniScreen.classList.add("hidden");
      this.screenVideoElement = null;


      // var unpublish = {
      //   request: "unpublish",
      // };
      // this.screenConnection.send({
      //   message: unpublish,
      //   success: () => {
      //     this.localScreenShare = false;
      //     this.screenButtonBusy = false;

      //     this.localScreenStream.getTracks().forEach((track) => track.stop());

      //     this.localScreenStream = null;
      //     this.miniScreen.srcObject = null;
      //     this.miniScreen.classList.add("hidden");
      //     this.screenShare = null;
      //     this.screenVideoElement = null;
      //   },
      // });
    },

    registerScreenUsername() {
      // Create a new room
      Janus.log("Screen sharing session created: " + this.roomId);
      var register = {
        request: "join",
        room: this.roomId,
        ptype: "publisher",
        display: this.screenShareId,
        quality: 0,
      };

      this.sfutest.send({
        message: register,
      });
    },

    sendLog() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/client/video-log", {
          user_id: this.dataReport.user,
          user_type: "client",
          room_id: this.dataReport.room,
          userAgent: this.dataReport.userAgent,
          events: this.dataReport.events,
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    addEvent(event) {
      this.dataReport.events.push(event);
    },

    startP2PCall(callId) {
      if (this.socketInitialized) {
        this.p2pSocket.emit("connect_to_call", { callId });
      } else {
        console.log('socket not initialized');
      }
    },
    startPeerConnection(stream, isScreen) {
      isScreen = isScreen || false;

      let peerConnectionType = isScreen ? 'screen' : 'call';
      let peerConnection;

      peerConnection = new RTCPeerConnection({
        iceServers: [
          { urls: "stun:stun.l.google.com:19302" },
          {
            urls: "turn:turn.swandoola.com:443",
            username: "user",
            credential: "7VyQFJ2k98KaJ79esWHU"
            //TODO: integrate credentials with consultations
          },
        ]
      });

      this.peerConnections[peerConnectionType] = peerConnection;
      this.peerConnectionTimers[peerConnectionType] = this.monitorPcStats(peerConnection, peerConnectionType);

      peerConnection.addEventListener('icecandidate', (event) => {
        this.onIceCandidate(event, isScreen);
      }, false);

      peerConnection.ontrack = (event) => {
        this.ontrack(event, isScreen);
      }

      if (!isScreen) {
        peerConnection.oniceconnectionstatechange = (event) => {
          this.handleICEState(peerConnection.iceConnectionState);
        };
      }

      if (stream) {
        stream.getTracks().forEach(track => peerConnection.addTrack(track, stream));
        this.setVP8Codec(peerConnection, stream);
      }
    },
    createOffer(isScreen) {
      isScreen = isScreen || false;

      let peerConnectionType = isScreen ? 'screen' : 'call';
      let peerConnection = this.peerConnections[peerConnectionType];

      peerConnection.createOffer(this.pcOptions)
        .then((offer) => {
          return peerConnection.setLocalDescription(offer);
        })
        .then(() => {
          this.p2pSocket.emit('webrtc_info', {
            type: 'offer',
            sdp: peerConnection.localDescription.sdp,
            userId: this.userContact,
            isScreen: isScreen,
          });
        })
        .catch((reason) => {
          // can't create offer
      });
    },
    onIceCandidate(event, isScreen) {
      if (event.candidate) {
        this.p2pSocket.emit('webrtc_info', {
          type: 'candidate',
          candidate: event.candidate.candidate,
          sdpMid: event.candidate.sdpMid,
          sdpMLineIndex: event.candidate.sdpMLineIndex,
          userId: this.userContact,
          isScreen: isScreen,
        });
      }
    },
    ontrack(event, isScreen) {
      console.log('got new track');

      if (event.streams && event.streams[0]) {
        this.setRemoteStream(event.streams[0], isScreen);
      }
    },
    initializeSocket() {
      // previous socket connection
      this.socket = io(process.env.VUE_APP_CONSULTATION_NODE_SERVER, {
        transports: ['websocket'],
        query:
          "room_var=" +
          this.$route.params.id +
          "&user_email_var=" +
          this.$store.user.email,
      });

      // new socket connection
      const userId = this.$store.user.id;

      this.p2pSocket = io('https://turn.swandoola.com:8080', {
        transports: ['websocket'],
        forceNew: true,
        reconnection: true,
        query: {
          userId: userId,
          userToken: "user_token_" + userId,
          dropPreviousConnection: false,
        }
      });

      this.socket.on("connect", () => {
        console.log("connected to socket");
        // this.sendRoomDetails();
      });

      this.socket.on("fileShared", (file) => {
        console.log("file has been shared by user", file);
        this.fileBeingShared = file;
        this.$refs.remoteVideosFull.classList.add("screen-sharing-active");

        this.$refs.remoteVideosFull.classList.remove("h-full");
        var allRemoteVideos = document.getElementsByClassName(
          "remote-video-player"
        );
        allRemoteVideos.forEach((v) => {
          v.classList.remove("h-screen");
          v.classList.add("h-28");
        });
      });

      this.socket.on("fileSharingEnded", (file) => {
        console.log("file share ended by user");
        this.fileBeingShared = null;
        this.currentPage = 1;
        this.pageCount = 0;

        this.$refs.remoteVideosFull.classList.remove("screen-sharing-active");
        var allRemoteVideos = document.getElementsByClassName(
          "remote-video-player"
        );
        allRemoteVideos.forEach((v) => {
          v.classList.remove("h-28");
          v.classList.add("h-screen");
        });
      });

      this.socket.on("fileShareNextPage", () => {
        console.log("next page clicked");
        this.currentPage++;
      })

      this.socket.on("fileSharePreviousPage", () => {
        console.log("prev page clicked");
        this.currentPage--;
      });

      // new callbacks for P2P
      this.p2pSocket.on('session_initialized', () => {
        console.log("session_initialized");
        this.socketInitialized = true;
        this.startP2PCall(this.roomId);
      });
      this.p2pSocket.on('connection_refused', (data) => {
        console.log("connection_refused", data);
      });
      this.p2pSocket.on('on_connect_to_call', (data) => {
        if (data.success) {
          const myId = this.$store.user.id;
          this.users = data.users;
          this.users.forEach(id => {
            if (id != myId) {
             this.userContact = id;
            }
          });
        }
      });
      this.p2pSocket.on('another_user_connected', (data) => {
        const userId = data.userId;
        this.userContact = userId;
        if (this.users.indexOf(userId) == -1) {
          this.users.push(userId);
        }

        this.startPeerConnection(this.localStream);
        this.createOffer();

        let peerConnectionExist = this.peerConnections['screen'];

        if (this.screenShareStream) {
          this.startPeerConnection(this.screenShareStream, true);

          if (!peerConnectionExist) {
            this.createOffer(true);
          }
        }
      });
      this.p2pSocket.on('webrtc_info', async (data) => {
        console.log("new message", data);

        let isScreen = data.isScreen;
        let peerConnectionType = isScreen ? 'screen' : 'call';
        let peerConnection = this.peerConnections[peerConnectionType];

        switch (data.type) {
          case 'offer':
            if (!peerConnection) {
              let stream = isScreen ? this.screenShareStream : this.localStream;

              this.startPeerConnection(stream, isScreen);
              peerConnection = this.peerConnections[peerConnectionType];
            }

            peerConnection.setRemoteDescription({type: 'offer', sdp: data.sdp})
              .then(() => {
                return peerConnection.createAnswer(this.pcOptions);
              })
              .then((answer) => {
                return peerConnection.setLocalDescription(answer);
              })
              .then(() => {
                this.candidates.forEach((candidate) => {
                  peerConnection.addIceCandidate(candidate);
                });

                this.candidates = [];

                this.p2pSocket.emit('webrtc_info', {
                  type: 'answer',
                  sdp: peerConnection.localDescription.sdp,
                  userId: this.userContact,
                  isScreen: isScreen,
                });
              })
              .catch((error) => {
                console.error('can\'t create answer:', error);
              });
            break;
          case 'answer':
            try {
              await peerConnection.setRemoteDescription({type: 'answer', sdp: data.sdp})

              this.candidates.forEach((candidate) => {
                peerConnection.addIceCandidate(candidate);
              });

              this.candidates = [];
            } catch (error) {
              console.error('can\'t set remote description:', error);
            }
            break;
          case 'candidate':
            if (data.candidate) {
              let candidate = new RTCIceCandidate({sdpMLineIndex:data.sdpMLineIndex, candidate: data.candidate});

              if (peerConnection.remoteDescription) {
                peerConnection.addIceCandidate(candidate);
              } else {
                this.candidates.push(candidate);
              }
            }
            break;
        }
      });
      this.p2pSocket.on('user_disconnected', (data) => {
        console.log("user_disconnected", data);
        if (this.userContact == data.userId) {
          ['call', 'screen'].forEach((type) => {
            clearTimeout(this.peerConnectionTimers[type]);

            if (this.peerConnections[type]) {
              let isScreen = (type == 'screen');

              this.peerConnections[type].close();
              this.peerConnections[type] = null;
              this.setRemoteStream(null, isScreen);
            }
          });
        }
      });
      this.p2pSocket.on('stream_ended', (data) => {
        console.log("stream_ended", data);

        let peerConnectionType = data.isScreen ? 'screen' : 'call';
        clearTimeout(this.peerConnectionTimers[peerConnectionType]);

        this.setRemoteStream(null, data.isScreen);

        if (this.peerConnections[peerConnectionType]) {
          let keepPeerConnection = data.isScreen && (this.screenShareStream || this.screenShare);

          if (!keepPeerConnection) {
            this.peerConnections[peerConnectionType].close();
            this.peerConnections[peerConnectionType] = null;
          }
        }
      });
      this.p2pSocket.on('call_finished', (data) => {
        console.log("call_finished", data);
      });
    },
    setRemoteStream(stream, isScreen) {
      if (isScreen) {
        if (stream) {
          this.$refs.remoteScreenVideoElement.srcObject = stream;
          this.$refs.remoteScreenVideoElement.classList.remove("hidden");

          this.$refs.remoteVideosFull.classList.add(
            "screen-sharing-active"
          );

          this.$refs.remoteVideosFull.classList.remove("h-full");

          document
            .getElementById("remote-videos-full")
            .classList.remove("screen-sharing-active");

          var allRemoteVideos = document.getElementsByClassName(
            "remote-video-player"
          );
          allRemoteVideos.forEach((v) => {
            v.classList.remove("h-screen");
            v.classList.add("h-28");
          });
          this.screenShare = true;
        } else {
          this.$refs.remoteVideosFull.classList.remove(
            "screen-sharing-active"
          );

          document
            .getElementById("remote-videos-full")
            .classList.remove("screen-sharing-active");

          this.$refs.remoteVideosFull.classList.add("h-full");

          var allRemoteVideos = document.getElementsByClassName(
            "remote-video-player"
          );
          allRemoteVideos.forEach((v) => {
            v.classList.remove("h-28");
            v.classList.add("h-screen");
          });

          this.screenShare = false;
          this.$refs.remoteScreenVideoElement.classList.add("hidden");
        }
      } else {
        var remoteVideoExists = document.getElementById("remote-video-div");

        if (stream) {
          if (!remoteVideoExists) {
            var divAppend = document.createElement("div");
            divAppend.classList += "bg-black flex justify-center mt-2";
            divAppend.id = "remote-video-div";

            var actualVideoEl = document.createElement("video");
            actualVideoEl.ref = "remote-video";
            actualVideoEl.id = "remote-video";
            actualVideoEl.classList +=
              "remote-video-player max-h-screen h-screen w-auto";
            actualVideoEl.setAttribute('autoplay', true);
            actualVideoEl.setAttribute('playsinline', true);
            actualVideoEl.srcObject = stream;

            divAppend.appendChild(actualVideoEl);

            this.$refs.remoteVideosFull.appendChild(divAppend);
            this.remoteFeedCount++;
          } else {
            var actualVideoEl = document.getElementById("remote-video");
            actualVideoEl.srcObject = stream;
          }
        } else {
          if (remoteVideoExists) {
            remoteVideoExists.remove();
            this.remoteFeedCount--;
          }
        }
      }
    },
    setGetUserMediaError() {
      this.checkCompatability.streamRetrievingProblem = true;
    },
    setVP8Codec(peerConnection, stream) {
      const supportsSetCodecPreferences = window.RTCRtpTransceiver &&
        'setCodecPreferences' in window.RTCRtpTransceiver.prototype;

      if (supportsSetCodecPreferences) {
        let preferredCodec = 'video/VP8';
        const {codecs} = RTCRtpSender.getCapabilities('video');
        const selectedCodecIndex = codecs.findIndex(c => c.mimeType === preferredCodec);

        if (selectedCodecIndex != -1) {
          const selectedCodec = codecs[selectedCodecIndex];
          codecs.splice(selectedCodecIndex, 1);
          codecs.unshift(selectedCodec);

          const transceiver = peerConnection.getTransceivers()
            .find((transceiver) => {
              return transceiver.sender && transceiver.sender.track === stream.getVideoTracks()[0];
            });
          transceiver.setCodecPreferences(codecs);
          console.log('set prefered codec', preferredCodec);
        } else {
          console.log('prefered codec not found', preferredCodec);
        }
      } else {
        console.log('setting codec not supported');
      }
    },
    clearPeerconnections() {
      ['call', 'screen'].forEach((type) => {
        clearTimeout(this.peerConnectionTimers[type]);
      });

      if (this.screenShareStream) {
        this.endScreenShare();
      }

      if (this.p2pSocket) {
        this.p2pSocket.close();
        this.p2pSocket = null;
      }

      if (this.localStream) {
        this.localStream.getTracks().forEach(track => {track.stop();})
        this.localStream = null;
        this.setRemoteStream(null);
      }

      if (this.peerConnections['call']) {
        this.peerConnections['call'].close();
        this.peerConnections['call'] = null;
      }

      this.connected = false;
    },
    monitorPcStats(pc, type) {
      let lastShown;
      let showInterval = 30000;
      let lastInfo = {
        audio: {
          packetsLost: 0,
          packetsReceived: 0,
          packetsLostPercentage: 0,
        },
        video: {
          packetsLost: 0,
          packetsReceived: 0,
          packetsLostPercentage: 0,
        },
      };

      let getPcStats = () => {
        pc.getStats(null)
          .then(stats => {
            let time = Date.now();

            stats.forEach(report => {
              if (report.type === 'inbound-rtp' && ['audio', 'video'].includes(report.kind)) {
                let packetsLost = report.packetsLost - lastInfo[report.kind]['packetsLost'];
                let packetsReceived = report.packetsReceived - lastInfo[report.kind]['packetsReceived'];

                let packetsLostPercentage = Math.floor((packetsLost / (packetsReceived + packetsLost)) * 100);

                lastInfo[report.kind]['packetsLostPercentage'] = packetsLostPercentage;
                lastInfo[report.kind]['packetsLost'] = report.packetsLost;
                lastInfo[report.kind]['packetsReceived'] = report.packetsReceived;
              }
            });

            let audioPacketsLostPercentage = lastInfo['audio']['packetsLostPercentage'];
            let videoPacketsLostPercentage = lastInfo['video']['packetsLostPercentage'];

            if (audioPacketsLostPercentage >= this.packetsLostThreshold) {
              this.handleSlowLink(false, lastInfo['audio']['packetsLost']);
            } else if (videoPacketsLostPercentage >= this.packetsLostThreshold) {
              this.handleSlowLink(false, lastInfo['video']['packetsLost']);
            } else {
              this.hideNotification("packetsLost");
            }

            this.peerConnectionTimers[type] = setTimeout(getPcStats, 1000);
          });
      };

      getPcStats();
    },
  },
  beforeDestroy() {
    if (this.micTest) {
      this.micTest.stop();
    }

    this.clearPeerconnections();
  },
  components: {
    ControlArea,
    PdfViewer,
    FeedbackModal,
    Preflight,
  },
};
</script>

<style lang="scss">
#router-view {
  padding: 0 !important;
}
.screen-sharing-active {
  position: absolute;
  bottom: 0.75rem;
  right: 11rem;
  width: auto;
  max-height: 10rem;
  z-index: 10;
}

video.local-video {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  /* Safari and Chrome */
  -moz-transform: rotateY(180deg);
  /* Firefox */
}
</style>