<template>
  <div>
    <janus-video-room
      :room-data="{ id: $route.params.id }"
      :user-data="$store.user"
      :stream-enabled="false"
    ></janus-video-room>
  </div>
</template>

<script>
import JanusVideoRoom from "./JanusVideoRoom";

export default {
  data() {
    return {};
  },
  mounted() {},
  components: {
    JanusVideoRoom,
  },
};
</script>

<style>
.navbar {
  display: none;
}
</style>

