<template>
  <pdf
    :src="file.url"
    :page="currentPage"
    @num-pages="$parent.pageCount = $event"
  ></pdf>
</template>

<script>
import Pdf from "vue-pdf";
export default {
  props: ["currentPage", "pageCount", "file"],
  data() {
    return {};
  },
  components: {
    Pdf,
  },
};
</script>

<style>
</style>